import React from 'react'; 


class NavBar extends React.Component{
  
    render(){
    return(
        <div>  

        <div id="shopify-section-header" className="shopify-section shopify-section--header navbar-extra-space"><header id="section-header" className="Header Header--sidebar Header--initialized " data-section-id="header" data-section-type="header" data-section-settings="{
            &quot;navigationStyle&quot;: &quot;sidebar&quot;,
            &quot;hasTransparentHeader&quot;: false,
            &quot;isSticky&quot;: true
          }" role="banner">
            <div className="Header__Wrapper">
              <div className="Header__FlexItem Header__FlexItem--fill">
             
               </div>
          
          
          
              
          <div className="Header__FlexItem"><h2 className="Header__Logo"><a className="Header__LogoLink"><span className="Heading u-h4">FLAYR</span></a></h2></div>
          
              <div className="Header__FlexItem Header__FlexItem--fill">
                
          
          
          
              </div>
            </div>
          </header>
          
          
          </div>
          </div>
    )
}
}

export default NavBar;