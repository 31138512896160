import React from 'react';
import Checkout from './components/Checkout/Checkout';
import axios from 'axios';
import ErrorPage from 'next/error';
import Message from './components/Message/Message';
import './styles/shopify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/custom.css';
import './styles/checkout.css';
import 'react-phone-number-input/style.css'
import './styles/checkoutForm.css';

class CheckoutRender extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      found: '',
      loading: true,
      quote: '',
      countryDetails: '',
      stylist: '',
      request: '',
      booked: ''
    }
  }

  async componentDidMount() {
    try {
      const { requestId } = this.props.match.params;
      const request = await axios.get(`https://express-server-ap-southeast-2.flayr.io/request?request_id=${requestId}`);
      if (request.data.found === false) {
        this.setState({
          found: false,
          loading: false,
          booked: false
        })
      } else if (request.data.stage === 30) {
        this.setState({
          booked: true,
          id: request.data.request_id,
          loading: false,
          found: false,
        })
      }

      const [quote, stylist] = await Promise.all([axios.get(`https://express-server-ap-southeast-2.flayr.io/quote/${request.data.search_id}`),
      axios.get(`https://express-server-ap-southeast-2.flayr.io/stylist/${request.data.provider_id}`)])

      const country = await axios.get('https://restcountries.eu/rest/v2/alpha/' + stylist.data.body.country);

      this.setState({
        loading: false,
        quote: quote.data,
        countryDetails: country.data,
        stylist: stylist.data.body,
        request: request.data
      })
    }
    catch (err) {
      console.log(err);
      this.setState({
        found: false,
        loading: false
      })
    }
  }

  render() {

    return (
      <React.Fragment>
        {this.state.loading === false &&
          <>
            {this.state.found === false && this.state.booked !== true &&
              <ErrorPage statusCode={404} />
            }
            {(this.state.found === true || this.state.found === '') &&
              <Checkout quote={this.state.quote} countryDetails={this.state.countryDetails} stylist={this.state.stylist} request={this.state.request} />
            }
            {this.state.booked === true && this.state.loading === false && this.state.found === false &&
              <Message message={`Thank you for booking through FLAYR. Please check your email for your booking details. Your Booking Id is: ${this.state.id}`} />
            }

          </>
        }

      </React.Fragment>
    );
  }

}

export default CheckoutRender;