let BASE_SERVER_URL;
let STRIPE_PUBLIC_KEY;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  BASE_SERVER_URL = 'http://localhost:8081'
  STRIPE_PUBLIC_KEY = 'pk_test_SmmG34jF1ZIA4pCKXW9VIbXh'
} else {
  // production code
  BASE_SERVER_URL = 'https://flayr-api-dev.flayr.io'
  STRIPE_PUBLIC_KEY = 'pk_live_wxQfcBjWKJt5qdhZmeXzs7yM'
}



export { BASE_SERVER_URL, STRIPE_PUBLIC_KEY };