import React from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import { Col, Button, Form, FormGroup, Input, FormText, InputGroup, InputGroupAddon } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import Script from 'react-load-script';
import axios from 'axios';
import Intercom from 'react-intercom';
import { BASE_SERVER_URL } from '../../Constants';
import StripeLogo from '../../svg/stripe1';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-127854499-2');
ReactGA.plugin.require('ecommerce');
ReactGA.plugin.require('ec');




const fields = ["location", "country", "state", "postcode", "email", "mobile", "name", "suburb"]
let page1fields = ["location", "country", "state", "suburb", "postcode", "email", "mobile"]

let page1rules = {
  location: /^(?!\s*$).+/, //cant be blank
  country: /./, //any char
  state: /./,//any char
  postcode: /./, //any char
  email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, //type=email
  mobile: /^[+](?!\s*$)[0-9]*$/, //cant be blank + only numbers
  suburb: /./ //any char
}

const page2rules = {
  name: /^(?!\s*$).+/ //cant be blank
}

let randomNames = {}

const CardIcons = () => {
  return (<span className="radio__label__accessory">
    <span className="visually-hidden">
      Pay with:
  </span>
    <span data-brand-icons-for-gateway={15190196281}>
      <span className="payment-icon payment-icon--visa" data-payment-icon="visa">
        <span className="visually-hidden">
          Visa,
      </span>
      </span>              <span className="payment-icon payment-icon--master" data-payment-icon="master">
        <span className="visually-hidden">
          Mastercard,
      </span>
      </span>              <span className="payment-icon payment-icon--american-express" data-payment-icon="american-express">
        <span className="visually-hidden">
          American Express,
      </span>
      </span>
    </span>
  </span>)
}



class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      quoteName: '',
      email: '',
      mobile: '',
      location: '',
      appartment: '',
      country: '',
      state: '',
      postcode: '',
      suburb: '',
      name: '',
      exactChecked: false,
      rough: '',
      callingCode: '',
      locationMessage: '',
      cardClass: '',
      paymentStatus: '',
      page: 1,
      cardErrorMsg: '',
      valid: {}
    }
  }

  handleCardChange = (change) => {
    let valid = this.state.valid;
    valid.card = change.complete;
    let cardClass = '';

    if (!valid.card) {
      cardClass = 'StripeElement-invalid'
    }
    this.setState({
      valid: valid,
      cardClass: cardClass
    })
  };


  async componentDidMount() {
    for (let key of fields) {
      randomNames[key] = this.randomNameAttr();
    }
    this.setState({
      quoteName: this.props.quote.name,
      email: this.props.quote.email,
      postcode: this.props.quote.postcode,
      state: this.props.quote.state,
      country: this.props.countryDetails ? this.props.countryDetails.name : this.props.stylist.country,
      suburb: this.props.quote.suburb,
      callingCode: this.props.countryDetails ? this.props.countryDetails.callingCodes[0] : '',
      mobile: this.props.quote.mobile.includes("+") ? this.props.quote.mobile : `+${this.props.countryDetails.callingCodes[0]}${this.removeLeadingZero(this.props.quote.mobile)}`

    }, () => {
      this.validateFields(page1rules, true);
      this.sendEcData();
      ReactGA.pageview(window.location.pathname + window.location.search);
    })

  }

  submit = async (ev) => {
    // User clicked submit
  }


  sendEcData = () => {
    ReactGA.plugin.execute('ec', 'addProduct', {
      id: `${this.props.quote.shopify_id}_${this.props.stylist.provider_id}`,
      name: `${this.props.quote.shopify_id}_${this.props.stylist.provider_id}`,
      brand: `${this.props.stylist.provider_id}`,
      category: 'makeup',
      price: `${this.props.request.take}.00`,
      quantity: 1
    });

    ReactGA.plugin.execute('ec', 'setAction', 'checkout', {
      step: this.state.page
    });
  }

  validateFields = (pageRulesMap, initialLoad) => {
    let valid = this.state.valid;
    for (const [key, value] of Object.entries(pageRulesMap)) {
      let isValid = value.test(this.state[key]);
      if (!isValid && initialLoad) {
        continue; //skip this one if initial load;
      }
      valid[key] = isValid;
    }
    this.setState({
      valid: valid
    })
  }

  onInputChange = (event) => {
    this.setState({
      locationMessage: '',
      [event.target.id]: event.target.value
    }, () => {
      let pageRules;
      if (this.state.page === 1) {
        pageRules = page1rules
      } else if (this.state.page === 2) {
        pageRules = page2rules
      }
      this.validateFields(pageRules, false);
    })

  }

  onMobileChange = (phone) => {
    this.setState({
      locationMessage: '',
      mobile: phone
    }, () => {
      let pageRules;
      if (this.state.page === 1) {
        pageRules = page1rules
      } else if (this.state.page === 2) {
        pageRules = page2rules
      }
      this.validateFields(pageRules, false);
    })
  }

  removeLeadingZero = (s) => {
    return s.replace(/^0+/, '');
  }

  handlePlaceSelect = () => {
    // Extract City From Address Object
    let addressObject = this.autocomplete.getPlace();
    const address = addressObject.address_components;

    let postcode;
    let state;
    for (let a of address) {
      if (a.types.includes("postal_code")) {
        postcode = a.short_name;
      }
      if (a.types.includes("administrative_area_level_1")) {
        state = a.short_name;
      }
    }

    if (postcode !== this.state.postcode || state !== this.state.state) {
      let valid = this.state.valid
      valid.location = false;
      this.setState({
        valid: valid,
        locationMessage: 'This offer only covers ' + this.state.suburb
      })
    } else {
      let valid = this.state.valid
      valid.location = true;
      console.log(addressObject.formatted_address)
      this.setState({
        location: addressObject.formatted_address.split(',')[0],
        locationMessage: '',
        valid: valid
      });
    }
  }

  handleScriptLoad = () => {
    // Declare Options For Autocomplete 
    var options = {
      componentRestrictions: { country: this.props.stylist.country.toString().toLowerCase() }
    };
    // Initialize Google Autocomplete 
    /*global google*/
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('location'),
      options);
    // Fire Event when a suggested name is selected
    this.autocomplete.addListener('place_changed',
      this.handlePlaceSelect);
  }


  onExactCheckboxChange = (event) => {
    this.setState({
      exactChecked: !this.state.exactChecked,
      rough: !this.state.exactChecked ? 'Rough ' : ''
    })
  }

  checkoutClick = (e) => {
    e.preventDefault();
    if (this.state.page === 1) {
      this.validateFields(page1rules)
    } else if (this.state.page === 2) {
      this.validateFields(page2rules)
      if (!this.state.valid.card) {
        this.setState({
          cardClass: "StripeElement-invalid StripeElement--outlined"
        })
      }

      if (this.state.valid.card === true && this.state.valid.name === true) {
        this.createPayment();
      }
    }
    if (this.state.exactChecked) {
      let valid = this.state.valid;
      valid.location = true;
      this.setState({
        valid: valid
      })
    }
    for (let key of page1fields) {
      if (!this.state.valid[key]) {
        return;
      }
    }
    if (this.state.page === 1) {
      this.props.history.push('/payment/' + this.props.request.request_id);


      this.props.summaryClose();
      this.setState({
        page: 2
      }, () => {
        this.sendEcData();
        ReactGA.pageview(window.location.pathname + window.location.search);
      })
    }
  }

  //generating token & processing payment
  createPayment = async () => {
    this.setState({
      paymentStatus: 'loading'
    })
    let tokenConfig = {
      name: this.state.name,
      currency: this.props.countryDetails.currencies[0].code.toLowerCase(),
      address_city: this.state.suburb,
      address_state: this.state.state,
      address_zip: this.state.postcode,
      address_line1: this.state.location,
      address_country: this.props.stylist.country
    }
    try {
      let { token } = await this.props.stripe.createToken(tokenConfig);
      let response = await axios.post(BASE_SERVER_URL + '/main-checkout/', {
        ...tokenConfig,
        quoteName: this.state.quoteName,
        email: this.state.email,
        token_id: token.id,
        provider_id: this.props.stylist.provider_id,
        quote_id: this.props.quote.shopify_id,
        event_date: this.props.quote.event_date,
        mobile: this.state.mobile,
        isRoughAddress: this.state.exactChecked,
        request_id: this.props.request.request_id
      });
      if (response.data.status === "success") {
        this.props.history.push('/thankyou/' + this.props.request.request_id);

        ReactGA.plugin.execute('ec', 'addProduct', {
          id: `${this.props.quote.shopify_id}_${this.props.stylist.provider_id}`,
          name: `${this.props.quote.shopify_id}_${this.props.stylist.provider_id}`,
          brand: `${this.props.stylist.provider_id}`,
          category: 'makeup',
          price: `${this.props.request.take}.00`,
          quantity: 1
        });

        ReactGA.plugin.execute('ec', 'setAction', 'purchase', {
          id: `${this.props.request.request_id}`,
          revenue: `${this.props.request.take}.00`
        });

        ReactGA.pageview(window.location.pathname + window.location.search);


        this.setState({
          paymentStatus: 'success',
          page: 3
        })
      } else if (response.data.status === "error" && response.data.msg.message) {
        this.setState({
          paymentStatus: '',
          cardErrorMsg: response.data.msg.message,
          cardClass: 'StripeElement-invalid'

        })
      } else if (response.data.status === "error") {
        this.setState({
          paymentStatus: '',
          cardErrorMsg: 'An error has occured with your payment.',
          cardClass: 'StripeElement-invalid'
        })
      }

    } catch (err) {
      console.log(err);
    }
  }


  backClickHandler = () => {
    this.props.summaryClose();
    let valid = this.state.valid;
    valid.card = false
    this.setState({
      valid: valid,
      page: 1
    }, () => {
      this.props.history.push('/book/' + this.props.request.request_id);
      ReactGA.pageview(window.location.pathname + window.location.search);
      this.handleScriptLoad();
      this.validateFields(page1rules);
    })
  }

  randomNameAttr = () => {
    let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let length = 8
    let result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  render() {

    const user = {
      name: this.state.quoteName,
      email: this.state.email,
      created_at: (new Date()).toISOString(),
    };

    let mobileGroupClass = '';

    if (this.state.valid.mobile === true) {
      mobileGroupClass = 'valid'
    } else if (this.state.valid.mobile === false) {
      mobileGroupClass = 'invalid'
    } else {
      mobileGroupClass = ''
    }


    let page1 = (<>

      <div style={{ paddingRight: '15px', paddingLeft: '15px' }} className="section__header">
        <h2 className="section__title">
          Event Address (where should the stylist come to)
        </h2>
        <div style={{ marginTop: '15px' }}><input type="checkbox" className="form-checkbox" checked={this.state.exactChecked} onChange={this.onExactCheckboxChange} /> I don't know the exact address yet.</div>

      </div>
      {!this.state.exactChecked &&
        <Col md={12}>
          <div className="form-label-group">
            <Input autoComplete={false} invalid={this.state.valid.location === false ? true : false} autoFocus valid={this.state.valid.location} type="location" id="location" className="form-control" placeholder=" " value={this.state.location} onChange={this.onInputChange} />
            <label htmlFor="location">{this.state.rough}Street Address / Place</label>
            <FormText>{this.state.locationMessage}</FormText>
          </div>
        </Col>
      }

      <Col md={12}>
        <div className="form-label-group">
          <Input autoComplete="none" invalid={this.state.valid.email === false ? true : false} valid={this.state.valid.email} type="email" name={randomNames.email} id="email" className="form-control" placeholder=" " value={this.state.email} onChange={this.onInputChange} />
          <label htmlFor="email">Email address</label>
        </div>
      </Col>
      <Col md={12}>
        <div className="mobile-wrapper-div">
          <InputGroup className="form-label-group mobile-input-group">
            <PhoneInput
              className={`form-control ${this.state.valid.mobile === false ? 'invalid-mobile-box' : ''} `}
              placeholder="Enter phone number"
              country={this.props.stylist.country}
              value={this.state.mobile}
              onChange={this.onMobileChange} />
            <label className="mobile-label">Mobile</label>
            <div> </div>
            <InputGroupAddon className={`${mobileGroupClass}-mobile`} addonType="append">
              <div className="px-4">&nbsp;</div>
            </InputGroupAddon>
          </InputGroup>
        </div>
      </Col>
      <Col md={6}>
        <div className="form-label-group">
          <Input readOnly={true} autoComplete="none" invalid={this.state.valid.suburb === false ? true : false} valid={this.state.valid.suburb} type="suburb" name={randomNames.suburb} id="suburb" className="form-control" placeholder=" " value={this.state.suburb} onChange={this.onInputChange} />
          <label htmlFor="suburb">Suburb/City</label>
        </div>
      </Col>
      <Col md={6}>
        <div className="form-label-group">
          <Input readOnly={true} autoComplete="none" valid={this.state.valid.country} type="country" name={randomNames.country} id="country" className="form-control" placeholder=" " value={this.state.country} onChange={this.onInputChange} />
          <label htmlFor="country">Country</label>
        </div>
      </Col>
      <Col xs={6}>
        <div className="form-label-group">
          <Input readOnly={true} autoComplete="none" valid={this.state.valid.state} type="state" name={randomNames.state} id="state" className="form-control" placeholder=" " value={this.state.state} onChange={this.onInputChange} />
          <label htmlFor="state">State</label>
        </div>

      </Col>
      <Col xs={6}>
        <div className="form-label-group">
          <Input readOnly={true} autoComplete="none" valid={this.state.valid.postcode} type="postcode" name={randomNames.postcode} id="postcode" className="form-control" placeholder=" " value={this.state.postcode} onChange={this.onInputChange} />
          <label htmlFor="postcode">Postcode</label>

        </div>
      </Col>

    </>)

    let page2 = (<>
      <Col style={{ display: 'flex' }} md={12}>

        <div className="section__header">
          <h2 className="section__title">
            Payment Information
        </h2>

        </div>
        <div style={{ marginLeft: 'auto', paddingTop: '3px' }}>
          <CardIcons />
        </div>

      </Col>

      <Col md={12}>
        <div className="form-label-group">
          <Input autoComplete="off" invalid={this.state.valid.name === false ? true : false} valid={this.state.valid.name} type="name" name={randomNames.name} id="name" className="form-control" placeholder=" " value={this.state.name} onChange={this.onInputChange} />
          <label htmlFor="name">Cardholder Name</label>
        </div>
      </Col>
      <Col md={12}>

        <div className="form-label-group">
          <CardElement
            className={this.state.cardClass}
            hidePostalCode={true}
            onChange={this.handleCardChange}
          />
          <div style={{ display: 'flex', marginTop: '3px' }}>
            <div><span style={{ color: 'red', fontSize: '16px' }}>{this.state.cardErrorMsg}</span></div>
            <div style={{ marginLeft: 'auto' }}><StripeLogo /></div>
          </div>

        </div>

      </Col>



    </>)

    return (
      <React.Fragment>
        <Intercom appID="mggzutk1" verticalPadding={40} />
        <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvDA0hOnuN-KijkcT9IFYapOH9042QSso&libraries=places"
          onLoad={this.handleScriptLoad}
        />
        <div className="wrap" >
          <div className="main">
            <div className="main__content">
              <div style={{ padding: '5px', marginBottom: '80px' }}>
                <Form autoComplete="off">
                  <FormGroup row>
                    {this.state.page === 1 &&
                      <>{page1}</>
                    }
                    {this.state.page === 2 &&
                      <>{page2}</>
                    }
                    <Col md={12} style={{ display: 'flex' }}>
                      {this.state.page === 2 && this.state.paymentStatus === '' &&
                        <div style={{ marginRight: 'auto', cursor: 'pointer' }} onClick={this.backClickHandler}>
                          <svg focusable="false" aria-hidden="true" className="icon-svg icon-svg--color-accent icon-svg--size-10 previous-link__icon" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10"><path d="M8 1L7 0 3 4 2 5l1 1 4 4 1-1-4-4" /></svg><span className="step__footer__previous-link-content">
                            Back</span>
                        </div>
                      }
                      {this.state.paymentStatus === 'success' &&
                        <div>
                          <h4 className="section__title">
                            Payment Successful. <br />
                          </h4>
                          <br />
                          A booking confirmation email will been sent to {this.state.email}
                          <br />
                          <br />
                          <br />

                          Booking ID: {this.props.request.request_id}
                        </div>

                      }

                      <div className="checkout-button-desktop ml-auto" style={{ marginTop: '-20px' }}>
                        {(this.state.paymentStatus === 'loading' || this.state.paymentStatus === 'success') &&
                          <div className={`circle-loader ${this.state.paymentStatus === 'success' ? 'load-complete' : ''}`}>
                            <div className="checkmark draw" style={{ display: this.state.paymentStatus === 'success' ? 'block' : 'none' }}></div>
                          </div>
                        }



                        {(this.state.paymentStatus === '' || this.state.paymentStatus === 'error') &&
                          <Button
                            onClick={this.checkoutClick}
                            className="checkout-form-button checkout-button-desktop">
                            {this.state.page === 1 &&
                              <span>To Payment Info</span>
                            }
                            {this.state.page === 2 &&
                              <span>Complete Booking</span>
                            }
                          </Button>

                        }
                      </div>
                    </Col>

                  </FormGroup>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <Button
          style={{ marginLeft: 'auto' }}
          onClick={this.checkoutClick}
          className="checkout-form-button checkout-button-mobile">
          {(this.state.paymentStatus === 'loading' || this.state.paymentStatus === 'success') &&
            <div className={`circle-loader ${this.state.paymentStatus === 'success' ? 'load-complete' : ''}`}>
              <div className="checkmark draw" style={{ display: this.state.paymentStatus === 'success' ? 'block' : 'none' }}></div>
            </div>
          }

          {this.state.paymentStatus === '' &&
            <span>
              {this.state.page === 1 &&
                <span>To Payment Info</span>
              }
              {this.state.page === 2 &&
                <span>Complete Booking</span>
              }
            </span>
          }

        </Button>
      </React.Fragment>
    );
  }
}

export default injectStripe(withRouter(CheckoutForm));