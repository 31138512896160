import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import ErrorPage from 'next/error';
import CheckoutRender from './CheckoutRender';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path='/book/:requestId' component={CheckoutRender} />
        <Route path='/payment/:requestId' component={CheckoutRender} />=
        <Route path='/thankyou/:requestId' component={CheckoutRender} />


        <Route component={() => { return <ErrorPage statusCode={404} /> }} />
      </Switch>

    );
  }
}

export default App;
