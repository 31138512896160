import React from 'react'

const Message = (props) => {
  return (
    <div style={{ color: 'rgb(0, 0, 0)', background: 'rgb(255, 255, 255)', fontFamily: '-apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif', height: '100vh', textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}><div>
      <style dangerouslySetInnerHTML={{ __html: "body { margin: 0 }" }} />
      <div style={{ display: 'inline-block', textAlign: 'center', lineHeight: '49px', height: '49px', verticalAlign: 'middle', padding: '40px' }}>
        <h1 style={{ fontSize: '14px', fontWeight: 'normal', lineHeight: 'inherit', margin: '0px', padding: '0px' }}>{props.message}</h1></div></div></div>

  )
}

export default Message;