import React from 'react';
import NavBar from '../NavBar/NavBar';
import OrderSummary from '../OrderSummary/OrderSummary';
import OrderSummaryBox from '../OrderSummaryBox/OrderSummaryBox';
import { Collapse } from 'reactstrap';
import CheckoutForm from '../CheckoutForm/CheckoutForm';
import { Elements, StripeProvider } from 'react-stripe-elements';
import { STRIPE_PUBLIC_KEY } from '../../Constants';

const serviceMap = new Map();
serviceMap.set("_makeup_only", "Makeup Only");
serviceMap.set("_hair_only", "Hair Only");
serviceMap.set("_makeup_and_hair", "Makeup & Hair");
serviceMap.set("_makeup_and_hair_up", "Makeup & Hair Up");
serviceMap.set("_makeup_and_hair_down", "Makeup & Hair Down");
serviceMap.set("_hair_down", "Hair Down");
serviceMap.set("_hair_up", "Hair Up");

const personMap = new Map();
personMap.set("bride", "Bride");
personMap.set("bridesmaid", "Bridesmaid");
personMap.set("nonbridal", "Non-Bridal");
personMap.set("mother", "Mother Of Bride/Groom");
personMap.set("flowergirl", "Flower Girl");



class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryText: "Show order summary",
      orderSummaryClosed: true,
      stripe: null
    }
  }

  async componentDidMount() {


    if (window.innerWidth > 1000) {
      this.setState({
        summaryText: this.state.summaryText === "Show order summary" ? "Hide order summary" : "Show order summary",
        orderSummaryClosed: !this.state.orderSummaryClosed
      });
    }
  }

  onOrderSummaryClick = () => {
    this.setState({
      summaryText: this.state.summaryText === "Show order summary" ? "Hide order summary" : "Show order summary",
      orderSummaryClosed: !this.state.orderSummaryClosed
    })
  }

  parsePersonServiceList = (serviceArray) => {
    let obj = []
    for (let i = 0; i < serviceArray.length; i++) {
      obj.push(personMap.get(serviceArray[i][0].toString()) + " " + serviceMap.get(serviceArray[i][1].toString()))
    }
    return obj;
  }

  summaryClose = () => {
    if (window.innerWidth < 1000) {
      this.setState({
        summaryText: "Show order summary",
        orderSummaryClosed: true
      })
    }

  }
  render() {
    return (
      <StripeProvider apiKey={STRIPE_PUBLIC_KEY}>
        <React.Fragment>
          <NavBar />
          <OrderSummary
            cost={parseFloat(Math.round(this.props.request.take * 100) / 100).toFixed(2)}
            onOrderSummaryClick={this.onOrderSummaryClick}
            summaryText={this.state.summaryText}
            currency={this.props.countryDetails.currencies[0].code}
            symbol={this.props.countryDetails.currencies[0].symbol}
            countryDetails={this.props.countryDetails}
            orderSummaryClosed={this.state.orderSummaryClosed} />
          <div className="content">
            <Collapse isOpen={!this.state.orderSummaryClosed}>
              <OrderSummaryBox
                cost={parseFloat(Math.round(this.props.request.take * 100) / 100).toFixed(2)}
                currency={this.props.countryDetails.currencies[0].code}
                symbol={this.props.countryDetails.currencies[0].symbol}
                stylist={this.props.stylist}
                quote={this.props.quote}
                request={this.props.request}
                serviceList={this.parsePersonServiceList(this.props.quote.person_service_list)}
                countryDetails={this.props.countryDetails}
              />
            </Collapse>
            <Elements>
              <CheckoutForm countryDetails={this.props.countryDetails} quote={this.props.quote} stylist={this.props.stylist} request={this.props.request} summaryClose={this.summaryClose} />
            </Elements>
          </div>
        </React.Fragment>
      </StripeProvider>
    )
  }
}




export default Checkout;